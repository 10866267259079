import {
    FF_SHARING_EASY_AUTOMATIONS,
    FF_SHARING_SAVED_SEARCH_CONTACT,
    FF_SHARING_SAVED_SEARCH_COMPANY,
    FF_SHARING_SAVED_SEARCH_ORDER,
    FF_SHARING_SAVED_SEARCH_VISITOR,
    FF_SHARING_SAVED_SEARCH_SUBSCRIPTION,
    FF_SHARING_SAVED_SEARCH_REFERRAL_PARTNER,
    FF_SHARING_TEXT_TEMPLATE_EMAIL,
    FF_SHARING_TEXT_TEMPLATE_SMS_BROADCAST,
    FF_SHARING_TEXT_TEMPLATE_SMS_AUTOMATION,
    FF_SHARING_TEXT_TEMPLATE_SMS_CUSTOM,
    FF_SHARING_ROLES,
    FF_SHARING_AFFILIATE_CUSTOM_FIELDS,
    FF_SHARING_LANDING_PAGE_POPUP,
} from '@/constants/featureFlag.constants';
import { RESOURCE_TYPES, ITEM_TYPES, RESOURCE_SUBTYPES } from '@keap-web/content-sharing-lib';

export const ITEM_TYPES_WITH_FLAGS = {
    ...ITEM_TYPES,
    [RESOURCE_TYPES.CUSTOM_FIELD]: {
        ...ITEM_TYPES[RESOURCE_TYPES.CUSTOM_FIELD],
        subtypes: {
            ...ITEM_TYPES[RESOURCE_TYPES.CUSTOM_FIELD].subtypes,
            [RESOURCE_TYPES.AFFILIATE_CUSTOM_FIELD]: {
                ...ITEM_TYPES[RESOURCE_TYPES.CUSTOM_FIELD].subtypes[RESOURCE_TYPES.AFFILIATE_CUSTOM_FIELD],
                featureFlag: FF_SHARING_AFFILIATE_CUSTOM_FIELDS,
            },
        },
    },
    [RESOURCE_TYPES.EASY_AUTOMATION]: {
        ...ITEM_TYPES[RESOURCE_TYPES.EASY_AUTOMATION],
        featureFlags: [FF_SHARING_EASY_AUTOMATIONS],
    },
    [RESOURCE_TYPES.SAVED_SEARCH]: {
        ...ITEM_TYPES[RESOURCE_TYPES.SAVED_SEARCH],
        featureFlags: [
            FF_SHARING_SAVED_SEARCH_CONTACT,
            FF_SHARING_SAVED_SEARCH_COMPANY,
            FF_SHARING_SAVED_SEARCH_ORDER,
            FF_SHARING_SAVED_SEARCH_VISITOR,
            FF_SHARING_SAVED_SEARCH_SUBSCRIPTION,
            FF_SHARING_SAVED_SEARCH_REFERRAL_PARTNER,
        ],
        subtypes: {
            [RESOURCE_SUBTYPES.SAVED_SEARCH_CONTACT]: {
                ...ITEM_TYPES[RESOURCE_TYPES.SAVED_SEARCH].subtypes[RESOURCE_SUBTYPES.SAVED_SEARCH_CONTACT],
                featureFlag: FF_SHARING_SAVED_SEARCH_CONTACT,
            },
            [RESOURCE_SUBTYPES.SAVED_SEARCH_COMPANY]: {
                ...ITEM_TYPES[RESOURCE_TYPES.SAVED_SEARCH].subtypes[RESOURCE_SUBTYPES.SAVED_SEARCH_COMPANY],
                featureFlag: FF_SHARING_SAVED_SEARCH_COMPANY,
            },
            [RESOURCE_SUBTYPES.SAVED_SEARCH_ORDER]: {
                ...ITEM_TYPES[RESOURCE_TYPES.SAVED_SEARCH].subtypes[RESOURCE_SUBTYPES.SAVED_SEARCH_ORDER],
                featureFlag: FF_SHARING_SAVED_SEARCH_ORDER,
            },
            [RESOURCE_SUBTYPES.SAVED_SEARCH_REFERRAL_PARTNER]: {
                ...ITEM_TYPES[RESOURCE_TYPES.SAVED_SEARCH].subtypes[RESOURCE_SUBTYPES.SAVED_SEARCH_REFERRAL_PARTNER],
                featureFlag: FF_SHARING_SAVED_SEARCH_REFERRAL_PARTNER,
            },
            [RESOURCE_SUBTYPES.SAVED_SEARCH_SUBSCRIPTION]: {
                ...ITEM_TYPES[RESOURCE_TYPES.SAVED_SEARCH].subtypes[RESOURCE_SUBTYPES.SAVED_SEARCH_SUBSCRIPTION],
                featureFlag: FF_SHARING_SAVED_SEARCH_SUBSCRIPTION,
            },
            [RESOURCE_SUBTYPES.SAVED_SEARCH_VISITOR]: {
                ...ITEM_TYPES[RESOURCE_TYPES.SAVED_SEARCH].subtypes[RESOURCE_SUBTYPES.SAVED_SEARCH_VISITOR],
                featureFlag: FF_SHARING_SAVED_SEARCH_VISITOR,
            },
        },
    },
    [RESOURCE_TYPES.TEXT_TEMPLATE]: {
        ...ITEM_TYPES[RESOURCE_TYPES.TEXT_TEMPLATE],
        featureFlags: [
            FF_SHARING_TEXT_TEMPLATE_EMAIL,
            FF_SHARING_TEXT_TEMPLATE_SMS_BROADCAST,
            FF_SHARING_TEXT_TEMPLATE_SMS_AUTOMATION,
            FF_SHARING_TEXT_TEMPLATE_SMS_CUSTOM,
        ],
        subtypes: {
            [RESOURCE_SUBTYPES.EMAIL_SMS_TEMPLATE]: {
                ...ITEM_TYPES[RESOURCE_TYPES.TEXT_TEMPLATE].subtypes[RESOURCE_SUBTYPES.EMAIL_SMS_TEMPLATE],
                featureFlag: FF_SHARING_TEXT_TEMPLATE_EMAIL,
            },
            [RESOURCE_SUBTYPES.SMS_BROADCAST_TEMPLATE]: {
                ...ITEM_TYPES[RESOURCE_TYPES.TEXT_TEMPLATE].subtypes[RESOURCE_SUBTYPES.SMS_BROADCAST_TEMPLATE],
                featureFlag: FF_SHARING_TEXT_TEMPLATE_SMS_BROADCAST,
            },
            [RESOURCE_SUBTYPES.SMS_AUTOMATION_TEMPLATE]: {
                ...ITEM_TYPES[RESOURCE_TYPES.TEXT_TEMPLATE].subtypes[RESOURCE_SUBTYPES.SMS_AUTOMATION_TEMPLATE],
                featureFlag: FF_SHARING_TEXT_TEMPLATE_SMS_AUTOMATION,
            },
            [RESOURCE_SUBTYPES.SMS_CUSTOM_TEMPLATE]: {
                ...ITEM_TYPES[RESOURCE_TYPES.TEXT_TEMPLATE].subtypes[RESOURCE_SUBTYPES.SMS_CUSTOM_TEMPLATE],
                featureFlag: FF_SHARING_TEXT_TEMPLATE_SMS_CUSTOM,
            },
        },
    },
    [RESOURCE_TYPES.ROLE]: {
        ...ITEM_TYPES[RESOURCE_TYPES.ROLE],
        featureFlags: [FF_SHARING_ROLES],
    },
    [RESOURCE_TYPES.LANDING_PAGE_POPUP]: {
        ...ITEM_TYPES[RESOURCE_TYPES.LANDING_PAGE_POPUP],
        featureFlags: [FF_SHARING_LANDING_PAGE_POPUP],
    },
};

export const EXPORT_TYPES = {
    BUNDLES: 'bundles',
    ITEMS: 'items',
};

export const SHARE_ITEM_STATUSES = {
    IDLE: 'IDLE',
    STAGED: 'STAGED',
    LOADING: 'LOADING',
    COMPLETE: 'COMPLETE',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
};

export const WARNING_REASONS = {
    CAMPAIGN_HAS_BARD: 'ContainsBardNode',
};
