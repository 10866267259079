import { defineStore } from 'pinia';
import {
    LOADING,
    SUCCESS,
    ERROR,
    IDLE,
} from '@/constants/loadingStatuses.constants';
import { loadFeatureFlags } from './featureFlags';
import { loadIsAuthorized } from '@/share/api';
import { loadLocale } from '@/i18n';
import { useSentry } from '@keap-web/analytics-lib/sentry';
import { useTutorialStore } from '@/stores/tutorials';
import { useAuthStore } from '@/stores/auth';
import { useAccountsStore } from '@/accounts/store';
import { useTenantSettingsStore } from '@/stores/tenant';

export const useGlobalStore = defineStore('global', {
    state: () => ({
        overlaysActive: 0,
        boot: {
            status: IDLE,
            error: null,
            isAuthorized: false,
        },
        featureFlags: {},
        locale: null,
        sentry: useSentry(),

        // TODO this might need to move to a different store
        // TODO implement in ticket: FS-62135
        canUseInternalCartId: true,
    }),

    actions: {
        activateOverlay() {
            this.overlaysActive++;
        },

        deactivateOverlay() {
            if (this.overlaysActive > 0) {
                this.overlaysActive--;
            }
        },

        loadBootStart() {
            this.boot.status = LOADING;
            this.boot.error = null;
        },

        loadBootSuccess({ featureFlags, locale, isAuthorized }) {
            this.boot.status = SUCCESS;
            this.boot.error = null;
            this.boot.isAuthorized = isAuthorized;
            this.featureFlags = featureFlags;
            this.locale = locale;
        },

        loadBootError(error) {
            this.boot.status = ERROR;
            this.boot.error = error;
        },

        resetBoot() {
            this.boot.status = IDLE;
            this.boot.error = null;
            this.boot.isAuthorized = false;
            this.featureFlags = {};
            this.locale = null;
        },

        async loadBoot({ reload = false } = {}) {
            if (this.boot.status === SUCCESS && !reload) {
                return;
            }

            this.loadBootStart();

            try {
                const locale = await loadLocale();
                const accountsStore = useAccountsStore();
                let { appId } = accountsStore;

                if (!appId) {
                    await accountsStore.loadAccounts();

                    const { loadAccountStatus, apps } = useAccountsStore();

                    if (loadAccountStatus === ERROR) {
                        this.loadBootError('Error loading accounts');

                        return;
                    }

                    appId = apps[0]?.tenantId;

                    accountsStore.updateAppId(appId);
                }

                let isAuthorized = false;
                let featureFlags = {};

                try {
                    const promises = [
                        loadIsAuthorized(appId),
                        accountsStore.loadPermissions({ userId: useAuthStore().user.id, tenantId: appId }),
                    ];

                    const responses = await Promise.all(promises);

                    [isAuthorized] = responses;
                } catch (e) {
                    this.sentry.captureException(e, { message: 'Error loading tenant access' });

                    if (e.response && e.response.status !== 403) {
                        throw e;
                    }
                }

                featureFlags = await loadFeatureFlags(appId);

                if (isAuthorized) {
                    const promises = [
                        useTutorialStore().load(appId, useAuthStore().user.id),
                        useAuthStore().loadTerms(appId),
                        useTenantSettingsStore().loadSettings(appId),
                    ];

                    await Promise.all(promises);
                }

                this.loadBootSuccess({ locale, featureFlags, isAuthorized });
            } catch (e) {
                this.sentry.captureException(e, { message: 'Error in boot call' });
                this.loadBootError(e);
            }
        },
    },
});
